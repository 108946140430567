<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import ElementOverlayItem from './ElementOverlayItem.vue';

const appStore = useAppStore();
const { hoveredElement, selectedElement, similarElements } = storeToRefs(appStore);
</script>

<template>
  <ElementOverlayItem :element="hoveredElement" is-hovered v-if="hoveredElement" />

  <ElementOverlayItem :element="selectedElement" is-active v-if="selectedElement" />

  <ElementOverlayItem v-for="(item, index) in similarElements" :element="item" is-similar :key="index" />
</template>
