import FloatingVue from 'floating-vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { InspectMode } from './composables/picker';
import router from './router';

import 'floating-vue/dist/style.css';
import './assets/main.css';

const pinia = createPinia();
const app = createApp(App);

const container = document.createElement('div');
const root = document.createElement('div');
root.setAttribute('id', 'zapscale-editor');

document.body.appendChild(container);

const link_1 = document.createElement('link');
link_1.setAttribute('rel', 'stylesheet');
link_1.setAttribute('href', 'https://rsms.me/inter/inter.css');

const link_2 = document.createElement('link');
link_2.setAttribute('rel', 'stylesheet');
link_2.setAttribute('href', `${import.meta.env.VITE_BASE_URL}/index.css`);

const shadowRoot = container.attachShadow({ mode: 'open' });
shadowRoot.appendChild(link_1);
shadowRoot.appendChild(link_2);
shadowRoot.appendChild(root);

const style = document.createElement('style');
style.textContent = `
[data-zs-inspect="${InspectMode.Hover}"] {
  cursor: crosshair !important;
}
`;
document.head.appendChild(style);

app.use(pinia);
app.use(router);
app.use(FloatingVue);
app.mount(root);
