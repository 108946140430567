import { createRouter, createMemoryHistory } from 'vue-router';
import { RouteName } from './route-name';

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: '/events',
      name: RouteName.AppEvents,
      component: () => import('@/views/AppEventsListView.vue'),
    },
    {
      path: '/events/new',
      name: RouteName.NewAppEvent,
      component: () => import('@/views/AppEventCreateView.vue'),
    },
    {
      path: '/events/:id/edit',
      name: RouteName.EditAppEvent,
      component: () => import('@/views/AppEventEditView.vue'),
      props: true,
    },
  ],
});

export default router;
