<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { storeToRefs } from 'pinia';
import IconChevronUpOutline from 'virtual:icons/ion/chevron-up-outline';
import IconClose from 'virtual:icons/fe/close';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

const appStore = useAppStore();
const { minimized, docked } = storeToRefs(appStore);
</script>

<template>
  <div
    class="flex h-12 flex-shrink-0 select-none items-center justify-between rounded-t-lg border-b border-gray-900/10 px-4"
    :class="{ 'rounded-t-none': docked, 'rounded-b-lg border-b-transparent': minimized }"
  >
    <div class="pointer-events-none flex shrink-0 items-center">
      <img src="@/assets/logo.png" alt="ZapScale Logo" class="mr-2 h-6 w-auto" />
    </div>
    <Menu as="div" class="relative mr-auto inline-block text-left">
      <div>
        <MenuButton
          class="group inline-flex w-full items-center rounded p-2 text-sm font-semibold text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300"
        >
          Events
          <IconChevronUpOutline class="mt-[1px] h-4 w-4 rotate-180 transform text-gray-500/90" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute z-10 mt-1 w-52 origin-top-left rounded-md bg-white font-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div class="py-1">
            <MenuItem disabled>
              <span class="block px-4 py-2 text-xs text-[0.625rem] uppercase text-gray-500">ZapScale</span>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                :class="[
                  active ? 'bg-blue-500 text-white' : 'text-gray-700',
                  'group flex w-full cursor-pointer px-4 py-2 text-sm',
                ]"
              >
                Events
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }" disabled>
              <button
                :class="[
                  active ? 'bg-blue-500 text-white' : 'text-gray-700',
                  'group pointer-events-none block w-full px-4 py-2 text-left text-sm',
                ]"
              >
                Outcomes
                <span :class="[active ? 'text-gray-50' : 'text-gray-500', 'align-super text-[0.625rem] font-semibold']"
                  >Coming soon</span
                >
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
    <slot name="drag-handle" />
    <div class="flex">
      <!-- <button
        type="button"
        class="mr-1 rounded p-0 text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300"
        @click="appStore.toggleDocked"
      >
        <IconDockRightSolid class="h-4 w-4" v-if="docked" />
        <IconDockRight class="h-4 w-4" v-else />
      </button> -->
      <button
        type="button"
        class="mr-1 rounded p-0 text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300"
        @click="appStore.toggleMinimized"
        v-if="!docked"
      >
        <IconChevronUpOutline class="h-4 w-4 transform" :class="{ 'rotate-180 ': !minimized }" />
      </button>
      <button
        type="button"
        class="rounded p-0 text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300"
        @click="appStore.close"
      >
        <IconClose class="h-4 w-4" />
      </button>
    </div>
  </div>
</template>
