<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { autoPlacement, offset, useFloating } from '@floating-ui/vue';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import IconEnter from 'virtual:icons/ant-design/enter-outlined';
import IconWebComponent from 'virtual:icons/fluent-mdl2/web-components';
import IconCursorClick from 'virtual:icons/heroicons-outline/cursor-click';
import IconArrowUpword from 'virtual:icons/material-symbols/arrow-upward-rounded';
import IconClose from 'virtual:icons/material-symbols/close-rounded';
import IconAlertCircleOutline from 'virtual:icons/ion/alert-circle-outline';
import IconCheckmarkCircleOutline from 'virtual:icons/ion/checkmark-circle-outline';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  element: Element;
  isActive?: boolean;
  isHovered?: boolean;
  isSimilar?: boolean;
}>();

const appStore = useAppStore();
const { hoveredElement, hoveredElementsStack } = storeToRefs(appStore);

const top = ref(0);
const left = ref(0);
const width = ref(0);
const height = ref(0);

const tooltip = ref<HTMLDivElement | null>(null);
const {
  x,
  y,
  strategy,
  update: updateTooltip,
} = useFloating(hoveredElement, tooltip, {
  middleware: [
    offset(12),
    autoPlacement({
      alignment: 'start',
    }),
  ],
});
const showTooltip = ref(false);

watch(hoveredElement, () => {
  if (!hoveredElement.value) {
    showTooltip.value = false;
  } else {
    showTooltip.value = true;
  }
});

const tagName = computed(() => {
  return hoveredElement.value?.tagName ?? '';
});

const happy = computed(() => {
  return ['a', 'button'].includes(tagName.value.toLocaleLowerCase());
});

const update = async () => {
  const rect = props.element.getBoundingClientRect();
  top.value = rect.top + window.scrollY;
  left.value = rect.left + window.scrollX;
  width.value = rect.width;
  height.value = rect.height;
  updateTooltip();
};

watch(
  () => props.element,
  () => {
    update();
  },
  {
    immediate: true,
  },
);

useEventListener('scroll', update, { capture: true, passive: true });
useEventListener(
  'resize',
  useDebounceFn(() => {
    update();
  }, 200),
  { passive: true },
);
</script>

<template>
  <div
    class="pointer-events-none absolute z-[2147483646] rounded-[1px] outline transition-all"
    :class="{
      'bg-sky-300/50 outline-2 outline-offset-2 outline-sky-300': isHovered,
      'bg-amber-400/40 outline-2 outline-offset-[3px] outline-amber-400': isActive,
      'bg-teal-400/50 outline-2 outline-offset-[3px] outline-teal-400': isSimilar,
    }"
    :style="{
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
    }"
  >
    <span class="pointer-events-none absolute -top-2 -right-2 flex h-3 w-3" v-if="isActive || isSimilar">
      <span
        class="absolute inline-flex h-full w-full animate-ping rounded-full"
        :class="[isActive ? 'bg-amber-400' : 'bg-teal-400']"
      ></span>
      <span
        class="relative inline-flex h-3 w-3 rounded-full"
        :class="[isActive ? 'bg-amber-400' : 'bg-teal-400']"
      ></span>
    </span>
  </div>

  <div
    ref="tooltip"
    class="z-[2147483647] rounded-sm bg-white p-4 text-xs leading-6 text-slate-700 shadow-xl ring-1 ring-slate-700/10 transition-all"
    :style="{
      position: `${strategy}`,
      top: `${y ?? 0}px`,
      left: `${x ?? 0}px`,
      width: 'max-content',
    }"
    v-if="showTooltip"
  >
    <ul class="space-y-2">
      <li class="flex items-center border-b pb-1">
        <IconWebComponent class="h-5 w-5 flex-shrink-0 text-sky-500" />
        <div class="ml-4 flex flex-grow items-center justify-between">
          <div>
            <strong class="font-semibold text-slate-900">Element</strong> -
            <span class="font-medium">
              {{ tagName }}
            </span>
          </div>
          <span class="flex items-center font-semibold text-green-500" v-if="happy"
            ><IconCheckmarkCircleOutline class="mr-1 h-4 w-4" /> Good to track</span
          >
          <span class="flex items-center font-semibold text-red-500" v-else
            ><IconAlertCircleOutline class="mr-1 h-4 w-4" /> Not fit for tracking</span
          >
        </div>
      </li>
      <li class="flex items-center">
        <IconArrowUpword class="h-5 w-5 text-sky-500" />
        <p class="ml-4">
          <strong class="font-semibold text-slate-900">Up Arrow</strong>
          - Highlight the parent element
        </p>
      </li>
      <li class="flex items-center" :class="{ 'opacity-30': !hoveredElementsStack.length }">
        <IconArrowUpword class="h-5 w-5 rotate-180 text-sky-500" />
        <p class="ml-4">
          <strong class="font-semibold text-slate-900">Down Arrow</strong>
          - Highlight previous element
        </p>
      </li>
      <li class="flex items-center">
        <IconCursorClick class="h-5 w-5 text-sky-500" />
        <p class="ml-4">
          <strong class="font-semibold text-slate-900">Left Click</strong>
          - Select the highlighted element
        </p>
      </li>
      <li class="flex items-center">
        <IconEnter class="h-5 w-5 text-sky-500" />
        <p class="ml-4">
          <strong class="font-semibold text-slate-900">SPACE / Enter</strong>
          - Select the highlighted element
        </p>
      </li>
      <li class="flex items-center">
        <IconClose class="h-5 w-5 text-sky-500" />
        <p class="ml-4">
          <strong class="font-semibold text-slate-900">ESC</strong>
          - Quit
        </p>
      </li>
    </ul>
  </div>
</template>
