<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from './stores/app';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { RouteName } from './router/route-name';
import { useDraggable, useResizeObserver } from '@vueuse/core';
import AppHeader from './components/AppHeader.vue';
import ElementOverlay from './components/ElementOverlay.vue';

const router = useRouter();
const appStore = useAppStore();
const { darkMode, minimized, hidden, docked } = storeToRefs(appStore);
const container = ref<HTMLElement | null>(null);
const handle = ref<HTMLElement | null>(null);
const innerWidth = window.innerWidth;
const { style } = useDraggable(container, {
  handle,
  initialValue: { x: innerWidth / 1.8, y: 120 },
  preventDefault: true,
});

useResizeObserver(container, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  appStore.setContainerHeight(height);
});

onMounted(() => {
  router.replace({ name: RouteName.AppEvents });
});
</script>

<template>
  <div
    class="font-sans antialiased"
    :class="{
      'pointer-events-none opacity-0': hidden,
      dark: darkMode,
    }"
  >
    <div
      ref="container"
      class="fixed z-[2147483647] flex w-96 flex-col rounded-lg bg-slate-50 text-gray-900 ring-4 ring-slate-500 transition-opacity duration-500"
      :class="{
        '!top-0 !right-0 !bottom-0 !left-auto rounded-none border-t-0': docked,
        'h-[37.5rem] max-h-[37.5rem]': !docked,
        'h-[3.125rem]': minimized,
      }"
      :style="style"
    >
      <AppHeader>
        <template v-slot:drag-handle>
          <div ref="handle" class="w-full cursor-move" v-if="!docked">&nbsp;</div>
        </template>
      </AppHeader>
      <div class="flex-grow" :class="{ hidden: minimized }">
        <RouterView />
      </div>
    </div>
  </div>

  <ElementOverlay />
</template>
