import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useStorage } from '@vueuse/core';

export enum Mode {
  NORMAL = 'normal',
  HOVER = 'hover',
}

export const useAppStore = defineStore('app', () => {
  const mode = ref<Mode>();
  const minimized = ref(false);
  const hidden = ref(false);
  const docked = useStorage('zapscale-docked', false);
  const darkMode = useStorage('zapscale-dark-mode', false);
  const containerHeight = ref(0);
  const hoveredElement = ref<Element>();
  const hoveredElementsStack = ref<Element[]>([]);
  const selectedElement = ref<Element>();
  const similarElements = ref<Element[]>([]);

  const elementsCount = computed(() => {
    if (!selectedElement.value) {
      return 0;
    }

    return similarElements.value.length + 1;
  });

  const setMode = (val: Mode) => {
    mode.value = val;
  };

  const toggleMinimized = () => {
    minimized.value = !minimized.value;
  };

  const show = () => {
    hidden.value = false;
  };

  const hide = () => {
    hidden.value = true;
  };

  const toggleDocked = () => {
    docked.value = !docked.value;
    if (docked.value) {
      minimized.value = false;
    }
  };

  const toggleDarkMode = () => {
    darkMode.value = !darkMode.value;
  };

  const close = () => {
    sessionStorage.removeItem('_zs_editor');
    window.location.reload();
  };

  const setContainerHeight = (val: number) => {
    containerHeight.value = val;
  };

  return {
    mode,
    setMode,
    minimized,
    toggleMinimized,
    hidden,
    show,
    hide,
    docked,
    toggleDocked,
    darkMode,
    toggleDarkMode,
    close,
    containerHeight,
    setContainerHeight,
    hoveredElement,
    hoveredElementsStack,
    selectedElement,
    similarElements,
    elementsCount,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
