import { useAppStore } from '@/stores/app';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

export enum InspectMode {
  Hover = '0',
  Selected = '1',
  Similar = '2',
}

const IGNORED_ELEMENTS = [
  '#zapscale-editor',
  'input[type=checkbox]',
  'input[type=color]',
  'input[type=date]',
  'input[type=datetime-local]',
  'input[type=datetime]',
  'input[type=email]',
  'input[type=file]',
  'input[type=hidden]',
  'input[type=image]',
  'input[type=month]',
  'input[type=number]',
  'input[type=password]',
  'input[type=radio]',
  'input[type=range]',
  'input[type=reset]',
  'input[type=search]',
  'input[type=tel]',
  'input[type=text]',
  'input[type=time]',
  'input[type=url]',
  'input[type=week]',
  'textarea',
  'table',
  'td',
  'th',
  'tr',
].join(', ');

const INSPECT_ATTRIBUTE = 'data-zs-inspect';

export const usePicker = () => {
  const active = ref(false);

  const appStore = useAppStore();
  const { selectedElement, hoveredElement, hoveredElementsStack, similarElements } = storeToRefs(appStore);

  const observer = new MutationObserver(() => {
    if (!selectedElement.value) return;

    if (document.body.contains(selectedElement.value)) {
      return;
    }

    selectedElement.value = undefined;
    similarElements.value = [];
  });

  const elementClassNames = computed(() => {
    let value: string[] = [];
    if (selectedElement.value) {
      const classNames = selectedElement.value?.getAttribute('class');
      value = classNames?.length ? classNames.trim().split(/\s+/) : [];
    } else {
      value = [];
    }

    return value.filter((item) => item);
  });

  const elementSelector = computed(() => {
    let tagName = selectedElement.value?.tagName;

    if (!tagName) return;

    tagName = tagName.toLowerCase();

    let selector = '';

    if (elementClassNames.value.length) {
      selector = elementClassNames.value.map((v) => CSS.escape(v)).join('.');
      selector = `${tagName}.${selector}`;
      try {
        document.querySelectorAll(selector);
      } catch (error) {
        selector = tagName;
      }
    } else {
      selector = tagName;
    }

    return selector;
  });

  watch(hoveredElement, (newEl, oldEl) => {
    oldEl?.removeAttribute(`${INSPECT_ATTRIBUTE}`);
    newEl?.setAttribute(`${INSPECT_ATTRIBUTE}`, InspectMode.Hover);
  });

  watch(selectedElement, () => {
    if (selectedElement.value) {
      selectedElement.value.setAttribute(`${INSPECT_ATTRIBUTE}`, InspectMode.Selected);
      highlightSimilarElements();
    }
  });

  const start = () => {
    if (active.value) return;

    active.value = true;
    selectedElement.value = undefined;
    hoveredElement.value = undefined;
    similarElements.value = [];
    hoveredElementsStack.value = [];

    removeInspectAttribute();
    attachDomEvents();
  };

  const capture = () => {
    if (!hoveredElement.value || hoveredElement.value.matches(IGNORED_ELEMENTS)) {
      alert(`Element not supported.`);
      return;
    }

    active.value = false;
    selectedElement.value = hoveredElement.value;
    hoveredElement.value = undefined;
    hoveredElementsStack.value = [];

    detachDomEvents();
    observer.observe(document.body, { childList: true, subtree: true });
    appStore.show();
  };

  const abort = () => {
    active.value = false;
    hoveredElement.value = undefined;
    selectedElement.value = undefined;
    similarElements.value = [];
    hoveredElementsStack.value = [];

    removeInspectAttribute();
    detachDomEvents();
    observer.disconnect();
    appStore.show();
  };

  const highlightSimilarElements = () => {
    const selector = `${elementSelector.value}:not([${INSPECT_ATTRIBUTE}="${InspectMode.Selected}"])`;
    similarElements.value = Array.from(document.querySelectorAll(selector));
    for (let i = 0; i < similarElements.value.length; i++) {
      const element = similarElements.value[i];
      element.setAttribute(`${INSPECT_ATTRIBUTE}`, InspectMode.Similar);
    }
  };

  const highlightParent = () => {
    if (!hoveredElement.value) return;

    const parent = hoveredElement.value.parentElement;
    if (!parent || parent === document.body) return;
    hoveredElementsStack.value.push(hoveredElement.value);
    hoveredElement.value = parent;
  };

  const highlightChild = () => {
    if (!hoveredElement.value || !hoveredElementsStack.value.length) return;

    hoveredElement.value = hoveredElementsStack.value.pop();
  };

  const restrictElementsToText = (restrict?: boolean, text?: string) => {
    if (restrict) {
      similarElements.value = similarElements.value.filter((e) => {
        return e.textContent?.trim() === text;
      });
    } else {
      highlightSimilarElements();
    }
  };

  const removeInspectAttribute = () => {
    const elements = document.querySelectorAll(`[${INSPECT_ATTRIBUTE}]`);
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute(`${INSPECT_ATTRIBUTE}`);
    }
  };

  const stopEvent = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  const handleMouseMove = (event: MouseEvent) => {
    hoveredElementsStack.value = [];
    const element = event.target as Element;
    if (element === document.body) return;
    hoveredElement.value = element;
  };

  const handleClick = (event: MouseEvent) => {
    if (event.altKey) return;

    stopEvent(event);
    capture();
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    stopEvent(event);

    switch (event.code) {
      case 'Escape':
        abort();
        break;
      case 'ArrowUp':
        highlightParent();
        break;
      case 'ArrowDown':
        highlightChild();
        break;
      case 'Enter':
        capture();
        break;
      case 'Space':
        capture();
        break;
    }
  };

  const attachDomEvents = () => {
    detachDomEvents();
    document.addEventListener('mousemove', handleMouseMove, true);
    document.addEventListener('click', handleClick, true);
    document.addEventListener('keydown', stopEvent, true);
    document.addEventListener('keyup', handleKeyUp, true);
    document.addEventListener('mousedown', stopEvent, true);
    document.addEventListener('mouseup', stopEvent, true);
  };

  const detachDomEvents = () => {
    document.removeEventListener('mousemove', handleMouseMove, true);
    document.removeEventListener('click', handleClick, true);
    document.removeEventListener('keydown', stopEvent, true);
    document.removeEventListener('keyup', handleKeyUp, true);
    document.removeEventListener('mousedown', stopEvent, true);
    document.removeEventListener('mouseup', stopEvent, true);
  };

  return {
    start,
    abort,
    elementClassNames,
    elementSelector,
    restrictElementsToText,
  };
};
